import Player from '@vimeo/player';

function getCookie (name) {
	let value = `; ${document.cookie}`;
	let parts = value.split(`; ${name}=`);
	if (parts.length === 2) return parts.pop().split(';').shift();
}

function cookiesAreOkToUse() {
  return getCookie('approve_cookie') == '1'
}

function load_yt_api() {
  var tag = document.createElement('script');

  tag.src = "https://www.youtube.com/iframe_api";
  var firstScriptTag = document.getElementsByTagName('script')[0];
  firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
}


function update_countdown(elem, countdown_date) {
  // Get today's date and time
  let now = new Date().getTime();

  // Find the distance between now and the count down date
  let distance = countdown_date - now;

  // Time calculations for days, hours, minutes and seconds
  let days = Math.floor(distance / (1000 * 60 * 60 * 24));
  let hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
  var seconds = Math.floor((distance % (1000 * 60)) / 1000);

  // Display the result in the element with id="demo"
  elem.innerHTML = '<span>'+ days + '</span><span>' + hours + "</span><span>" + minutes + "</span><span>" + seconds + "</span>";
}


// Burger
const burger = document.getElementById('burger')
const menu = document.getElementById('menu-primary')

burger.addEventListener('click', (e) => {  
  burger.classList.toggle('is-open')
  menu.classList.toggle('is-open')
  
  if (!menu.classList.contains('is-activated'))
    menu.classList.add('is-activated')

  e.preventDefault();
})


// Messages
var t = setTimeout(function(){    
  
  document.querySelectorAll('meta[name=tf-msg]').forEach((item) => {
        
    fetch(item.getAttribute('content'))
      .then(response => {
        return response.json();
      }).then(data => {      
        const element = document.createElement('div');
        element.classList.add('pop-up');
        
        element.innerHTML = data['html'];
        document.querySelector('body').appendChild(element);
        
        document.querySelectorAll('.pop-up button').forEach((item) => {
          item.addEventListener('click', (e) => {
            
            if (e.currentTarget.dataset.value == '1') {
              load_yt_api()              
            }
            
            // Set cookie
            document.cookie = `${e.currentTarget.dataset.name}=${e.currentTarget.dataset.value}; path=/; max-age=${60 * 60 * 24 * 14};`;
            
            // Remove popup
            element.remove();
          })  
        })      
      }).catch(error => {
        console.info(error)
      });    
  })
}, 600);    


// Youtube
document.querySelectorAll('.yt-container').forEach((item) => {

  item.addEventListener('click', (e) => {    
    if (typeof YT === 'object') {
      new YT.Player(e.currentTarget, {
        videoId: e.currentTarget.dataset.youtube,
        playerVars: {
          'playsinline': 1,
          'modestbranding': 1
        },
        events: {
          'onReady': function(e){
            e.target.playVideo();
          }
        }
      });        
      e.preventDefault()
    }
  });
});

// Vimeo
document.querySelectorAll('.vimeo-container').forEach((item) => {

  item.addEventListener('click', (e) => {          
    if (cookiesAreOkToUse() && typeof Player === 'function') {        
      const vimeo_id = e.currentTarget.dataset.vimeo  
      
      const vimeo_div = document.createElement('div')
      vimeo_div.classList = e.currentTarget.classList
      
      e.currentTarget.parentNode.replaceChild(vimeo_div, e.currentTarget)        

      new Player(vimeo_div, {id: vimeo_id, byline: false, title: false, portrait: false, dnt: true, responsive: true, autoplay: true})
                
      e.preventDefault()
    }
  }, { once: true })
})


// Lazy load video
document.addEventListener("DOMContentLoaded", function() {
  
  if (getCookie('approve_cookie') == '1') {
    load_yt_api()
  }
  
  
  [...document.links].forEach(link => {
    if (link.hostname != window.location.hostname)
      link.target = '_blank'
  });
  
  // Countdown
  document.querySelectorAll('.countdown-container').forEach(elem => {
    
    let countdown_date = new Date(elem.getAttribute('datetime')).getTime();
    
    update_countdown(elem, countdown_date)

    // Update the count down every 1 minute
    var x = setInterval(function() {
    
      // Get today's date and time
      let now = new Date().getTime();
    
      // Find the distance between now and the count down date
      let distance = countdown_date - now;
    
      // If the count down is finished, write some text 
      if (distance < 0) {
        clearInterval(x);
        elem.innerHTML = "";
      } else {
        update_countdown(elem, countdown_date)
      }
    }, 1000 );

  })

})